export default {
  // 缅甸语
  word1001: 'Burmese',
  word1002: 'မေးလ်',
  word1003: 'စကားဝှက်',
  word1004: 'စကားဝှက်ကို မေ့နေသလား။',
  word1005: 'လော့ဂ်အင်',
  word1006: 'မှတ်ပုံတင်',
  word1007: 'အသုံးပြုသူသဘောတူညီချက်',
  word1008: 'ပုဂ္ဂိုလ်ရေးသီးသန့်',
  word1009: 'ကြှနျုပျတို့အကွောငျး',
  word1010: 'အကောင့်ကို ဗလာမဖြစ်ရပါ။',
  word1011: 'စကားဝှက်ကို ကွက်လပ်ထား၍မရပါ။',
  word1012: 'ဤအကောင့်ကို ပိတ်ထားသည်။',
  word1013: 'အသုံးပြုသူအမည် သို့မဟုတ် စကားဝှက် မမှန်ကန်ပါ။',
  word1014: 'အကောင့်ဝင်ခြင်း အောင်မြင်ပါသည်။',
  word1015: 'အမည်ပြောင်',
  word1016: 'ညာဘက်တွင် နံပါတ်ထည့်ပါ။',
  word1017: 'အတည်ပြုကုဒ်ကို ရယူပါ။',
  word1018: 'ထပ်လုပ်ပါ။',
  word1019: 'နံပါတ်သည် ဗလာမဖြစ်ရပါ။',
  word1020: 'မှန်ကန်ကြောင်း အတည်ပြုကုဒ်',
  word1021: 'စကားဝှက်နှစ်ခုသည် ကွဲလွဲနေသည်။',
  word1022: 'အမည်ပြောင်ဖြည့်ရပါမည်။',
  word1023: 'E-mail သည် အလွတ်မရနိုင်ပါ။',
  word1024: 'စကားဝှက်ကို ထပ်ခါတလဲလဲ အလွတ်မရနိုင်ပါ။',
  word1025: 'စက္ကန့်ပိုင်းအတွင်း ထပ်စမ်းကြည့်ပါ။',
  word1026: 'မှတ်ပုံတင်ခြင်းအောင်မြင်ပါပြီ',
  word1027: 'အီးမေးလ်အတည်ပြုကုဒ်',
  word1028: 'အတည်ပြုကုဒ်ကို ဖြည့်ရပါမည်။',
  word1029: 'စကားဝှက်အသစ်',
  word1030: 'စကားဝှက်ကိုပြောင်းရန်',
  word1031: 'စကားဝှက်အသစ်သည် ဗလာမဖြစ်နိုင်ပါ။',
  word1032: 'အောင်မြင်စွာ ပြင်ဆင်ပြီးပါပြီ။',
  AccountPlaceholder: 'မေးလ်',
  AccountEmptyTip: 'အီးမေးလ်အကောင့်ကို ဗလာမဖြစ်ရပါ။',
  PasswordPlaceholder: 'စကားဝှက်',
  PasswordEmptyTip: 'စကားဝှက်ကို ကွက်လပ်ထား၍မရပါ။',
  AccountForbidden: 'ဤအကောင့်ကို ပိတ်ထားသည်။',
  AccountPasswordError: 'အသုံးပြုသူအမည် သို့မဟုတ် စကားဝှက် မမှန်ကန်ပါ။',
  RealName: 'အမည်အမှန်',
  RealNameEmptyTip: 'အမည်ရင်းအတွက် အလွတ်မထားခဲ့ပါနဲ့။',
  RightNumber: 'ကျေးဇူးပြု၍ ညာဘက်ရှိ ပုံတွင် နံပါတ်ထည့်ပါ။',
  NumberEmptyTip: 'ပုံရှိ နံပါတ်သည် ဗလာမဖြစ်ရပါ။',
  EmailPlaceholder: 'မေးလ်',
  EmailEmptyTip: 'အီးမေးလ်အကောင့်ကို ဗလာမဖြစ်ရပါ။',
  EmailError: 'အီးမေးလ်ဖော်မတ်မှားနေပါသည်။',
  GetEmailCode: 'အီးမေးလ်အတည်ပြုကုဒ်ကို ရယူပါ။',
  Retry: 'စက္ကန့်ပိုင်းအတွင်း ထပ်စမ်းကြည့်ပါ။',
  Resend: 'ပြန်ပို့ပါ။',
  EmailCodePlaceholder: 'အီးမေးလ်အတည်ပြုကုဒ်',
  EmailCodeEmptyTip: 'အီးမေးလ်အတည်ပြုကုဒ်ကို ဗလာမဖြစ်ရပါ။',
  NewPassword: 'စကားဝှက်အသစ်',
  InputNewPassword: 'စကားဝှက်အသစ်ကို ထည့်ပါ။',
  AgainInputNewPassword: 'စကားဝှက်အသစ်ကို ထပ်မံထည့်သွင်းပါ။',
  PasswordLength: 'စကားဝှက်အရှည်သည် အက္ခရာ ၈ လုံးမှ ၁၆ လုံးကြားရှိသည်။',
  EmailHasRegister:
    'ဝမ်းနည်းပါသည်၊ ဤအီးမေးလ်လိပ်စာကို စာရင်းသွင်းထားပြီး၊ ကျေးဇူးပြု၍ အခြားအီးမေးလ်လိပ်စာကို အသုံးပြုပါ။',
  EmailHasExist: 'အသုံးပြုသူ၏ စာတိုက်ပုံးသည် ရှိနှင့်ပြီး သို့မဟုတ် ဖျက်ထားသည်။',
  EmailCodeInvalid: 'အီးမေးလ်အတည်ပြုကုဒ်သည် မမှန်ကန်ပါ၊ ကျေးဇူးပြု၍ ၎င်းကို ထပ်မံရယူပါ။',
  EmailCodeError: 'အီးမေးလ် အတည်ပြုကုဒ် အမှားအယွင်း',
  Confirm: 'သေချာပါတယ်။',
  AccountInexistence: 'ဤအကောင့်မရှိပါ၊ စကားဝှက်ကို ပြောင်းလဲ၍မရပါ။',
  ChangePasswordTip:
    'သင့်အကောင့်သည် ဘက်ထပ်မှပေးထားသော ပုံမှန်ကုဒ်စကားဝှက်ကို အသုံးပြုနေသည်။ မနက်ဖြန်ဆုံးမှ ကုဒ်စကားဝှက်ကို ပြောင်းလဲပါ။',
  Tip: 'အကြံပြုချက်',
  ChangePassword: 'ကုဒ်စကားဝှက်ကို ပြောင်းရန်',
  DeleteTip: 'သင့်အကောင့်ကိုဖျက်ထားသည်။ သင့်စီမံခန့်ခွဲသူကိုဆက်သွယ်ပါ။',
  AccountAbsentTip: 'အကောင့်မရှိသည်'
}
