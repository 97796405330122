export default {
  // 葡萄牙语
  word1001: 'Portuguese',
  word1002: 'Correspondência',
  word1003: 'senha',
  word1004: 'esqueceu a senha?',
  word1005: 'Conecte-se',
  word1006: 'registro',
  word1007: 'Termo de Acordo do Usuário',
  word1008: 'privacidade pessoal',
  word1009: 'sobre nós',
  word1010: 'A conta não pode ficar vazia',
  word1011: 'a senha não pode ficar em branco',
  word1012: 'Esta conta foi congelada',
  word1013: 'Usuário ou senha incorretos',
  word1014: 'login bem-sucedido',
  word1015: 'Apelido',
  word1016: 'Digite o número à direita',
  word1017: 'obter código de verificação',
  word1018: 'repita',
  word1019: 'O número não pode ficar vazio',
  word1020: 'Código de verificação incorreto',
  word1021: 'Duas senhas são inconsistentes',
  word1022: 'O apelido deve ser preenchido',
  word1023: 'O e-mail não pode ficar vazio',
  word1024: 'Repetir senha não pode ficar vazia',
  word1025: 'Tente novamente em segundos',
  word1026: 'Registro bem sucedido',
  word1027: 'Código de verificação de e-mail',
  word1028: 'o código de verificação deve ser preenchido',
  word1029: 'Nova Senha',
  word1030: 'alterar a senha',
  word1031: 'A nova senha não pode ficar vazia',
  word1032: 'Modificado com sucesso',
  AccountPlaceholder: 'Correspondência',
  AccountEmptyTip: 'A conta de e-mail não pode ficar vazia',
  PasswordPlaceholder: 'senha',
  PasswordEmptyTip: 'a senha não pode ficar em branco',
  AccountForbidden: 'Essa conta foi desativada',
  AccountPasswordError: 'Usuário ou senha incorretos',
  RealName: 'nome real',
  RealNameEmptyTip: 'Não deixe em branco o nome verdadeiro',
  RightNumber: 'Por favor insira o número na imagem à direita',
  NumberEmptyTip: 'O número na imagem não pode estar vazio',
  EmailPlaceholder: 'Correspondência',
  EmailEmptyTip: 'A conta de e-mail não pode ficar vazia',
  EmailError: 'O formato do e-mail está errado',
  GetEmailCode: 'Obtenha o código de verificação por e-mail',
  Retry: 'Tente novamente em segundos',
  Resend: 'Reenviar',
  EmailCodePlaceholder: 'Código de verificação de e-mail',
  EmailCodeEmptyTip: 'O código de verificação de e-mail não pode ficar vazio',
  NewPassword: 'Nova Senha',
  InputNewPassword: 'Por favor insira uma nova senha',
  AgainInputNewPassword: 'Por favor insira a nova senha novamente',
  PasswordLength: 'O comprimento da senha é entre 8 e 16 caracteres',
  EmailHasRegister: 'Desculpe, este endereço de e-mail já foi registrado, use outro endereço de e-mail',
  EmailHasExist: 'A caixa de correio do usuário já existe ou foi excluída',
  EmailCodeInvalid: 'O código de verificação de e-mail é inválido. Obtenha-o novamente',
  EmailCodeError: 'Erro no código de verificação de e-mail',
  Confirm: 'Claro',
  AccountInexistence: 'Esta conta não existe e a senha não pode ser alterada.',
  ChangePasswordTip:
    'Sua conta está utilizando uma senha padrão fornecida pelo sistema back-end. Por favor, altere sua senha assim que possível.',
  Tip: 'Sugestão',
  ChangePassword: 'Modificar senha',
  DeleteTip: 'Sua conta foi excluída. Por favor, entre em contato com o administrador da sua Câmara de Comércio.',
  AccountAbsentTip: 'A conta não existe'
}
