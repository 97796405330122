export default {
  // 印尼语
  word1001: 'Bahasa',
  word1002: 'Kotak surat',
  word1003: 'kode',
  word1004: 'Lupa password？',
  word1005: 'Log masuk',
  word1006: 'pendaftaran',
  word1007: 'Perjanjian pengguna',
  word1008: 'Privasi pribadi',
  word1009: 'Tentang kita',
  word1010: 'Nomor rekening tidak bisa kosong',
  word1011: 'Password tidak bisa kosong',
  word1012: 'Nomor rekening telah dibekukan',
  word1013: 'Nomor rekening atau kata sandi yang salah',
  word1014: 'Berhasil masuk',
  word1015: 'Nama panggilan',
  word1016: 'Masukkan nomor kanan',
  word1017: 'Dapatkan captcha',
  word1018: 'ulangi',
  word1019: 'Nomor tidak boleh kosong',
  word1020: 'Angka yang salah',
  word1021: 'Entri tidak cocok. Silakan masukkan ulang',
  word1022: 'Nama panggilan tidak bisa kosong',
  word1023: 'Kotak surat tidak bisa kosong',
  word1024: 'Mengulangi sandi tidak bisa kosong',
  word1025: ' detik setelah coba',
  word1026: 'Pendaftaran berhasil',
  word1027: 'Kode verifikasi email',
  word1028: 'Kode otentikasi tidak bisa kosong',
  word1029: 'Kata sandi baru',
  word1030: 'Ubah sandi',
  word1031: 'Password baru tidak bisa kosong',
  word1032: 'Revisi berhasil',

  // 新增
  AccountPlaceholder: 'Name Akun',
  AccountEmptyTip: 'Silakan masukkan nama akun',
  PasswordPlaceholder: 'Kata Sandi',
  PasswordEmptyTip: 'Silakan masukkan kata sandi',
  AccountForbidden: 'Akun itu telah dinonaktifkan',
  AccountPasswordError: 'Nama akun atau kata sandi salah',
  RealName: 'Nama Asli',
  RealNameEmptyTip: 'Silakan masukkan nama asli Anda',
  RightNumber: 'Silakan masukkan angka di sebelah kanan',
  NumberEmptyTip: 'Silakan masukkan angka di sebelah kanan',
  EmailPlaceholder: 'Email',
  EmailEmptyTip: 'Silakan masukkan alamat Email',
  EmailError: 'Itu format email yang salah',
  GetEmailCode: 'Dapatkan Kode Verifikasi',
  Retry: ' detik untuk mengirim ulang',
  Resend: 'Kirim ulang',
  EmailCodePlaceholder: 'Silakan masukkan kode verifikasi Email',
  EmailCodeEmptyTip: 'Kode verifikasi Email tidak boleh kosong',
  NewPassword: 'Kata sandi baru',
  InputNewPassword: 'Silakan masukkan kata sandi baru',
  AgainInputNewPassword: 'Silakan masukkan ulang kata sandi baru Anda',
  PasswordLength: 'Panjang kata sandi harus antara 8 hingga 16 karakter',
  EmailHasRegister: 'Maaf, email ini sudah terdaftar. Silakan gunakan email lain.',
  EmailHasExist: 'Email pengguna sudah ada atau sudah dihapus',
  EmailCodeInvalid: 'Kode verifikasi email tidak valid, silakan dapatkan lagi',
  EmailCodeError: 'Kode verifikasi email salah',
  Confirm: 'Konfirmasi',
  AccountInexistence: 'Akun tidak ada, dan kata sandi tidak dapat diubah.',
  ChangePasswordTip:
    'Akun Anda saat ini menggunakan kata sandi default yang diberikan oleh sistem backend. Mohon segera mengubah kata sandi Anda.',
  Tip: 'Petunjuk',
  ChangePassword: 'Ubah kata sandi',
  DeleteTip: 'Akun Anda telah dihapus. Silakan hubungi administrator Kamar Dagang Anda.',
  AccountAbsentTip: 'Akun tidak ada atau tidak ditemukan'
}
