// 设置 cookie
const setCookie = (name, value) => {
  // 7天后重新登录
  const days = 7
  let date = new Date()
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
  document.cookie = name + '=' + escape(value) + ';expires=' + date.toGMTString() + '; path=/'
}

//获取cookie
const getCookie = (name) => {
  var arr,
    reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
  if ((arr = document.cookie.match(reg))) return unescape(arr[2]);
  else return null;
}

export { setCookie, getCookie }
